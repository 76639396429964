<div class="input-container relative" *ngIf="state$ | async as state">
  <div *ngIf="!state.capitalCity.roundOver">
    <div class="input-wrapper">
      <div #dropdown *ngIf="showListBox" class="list-box">
        <a (click)="citySelect(item)" class="item text-black dark:!text-white !bg-white dark:!bg-slate-800"
          *ngFor="let item of list"
          [innerHTML]="getListItemHTML(item)">
        </a>
      </div>
      <input [formControl]="input" #inputBox (focus)="onFocus()" class="text-black dark:!text-white" type="text"
        placeholder="Enter a city" />
    </div>
    <button (click)="guess()"
      class="text-black dark:!text-white font-bold p-1 flex items-center justify-center border-2 uppercase my-0.5 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700 w-full rounded">
      <b>
        <span class="text-lg">📷</span>
        GUESS
      </b>
    </button>
  </div>
  <div [ngClass]="{ 'confetti-under': !showConfetti }" class="confetti" #confettiContainer></div>
</div>
