<div *ngIf="user$ | async as user" class="mt-3 text-black dark:!text-white relative flex flex-col gap-3">
  <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>

  <h2 class="text-xl font-bold">Archive</h2>
  <div *ngIf="!user?.subscription?.isPremium">
    <p class="mb-3">Access to the game archive is an exclusive feature for Premium Members. Register now through the link below and unlock the ability to play all the games that have ever existed!</p>
    <app-become-premium-member></app-become-premium-member>
  </div>
  <div *ngIf="user?.subscription?.isPremium" class="flex flex-col gap-3">
    <p>Here are all the games since 27th February 2023. Click on a game
      to play that day's game.
    </p>
    <div (click)="playRandomGame()"
      class="text-center bg-blue-600 hover:bg-blue-400 text-white p-2 rounded-md shadow-sm cursor-pointer">
      Play a random game
    </div>
    <!-- calendar -->
    <div class="w-full m-auto border rounded-md shadow-sm overflow-hidden">
      <div class="calendar-header">
        <button class="py-2 px-3 hover:underline" (click)="goToPreviousMonth()">Previous</button>
        <span class="text-xl">{{ currentDate.toLocaleString('default', { month: 'long' }) }} {{ currentYear }}</span>
        <button class="py-2 px-3 hover:underline" (click)="goToNextMonth()">Next</button>
      </div>
      <table class="calendar-table">
        <thead>
          <tr>
            <th *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" class="border">{{ day }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let week of calendarDays">
            <td *ngFor="let day of week" class="border relative" [ngClass]="{
                          'today-cell': isToday(day),
                          'bg-[#ccdcff] dark:bg-[#2563eb]': isGameDay(day),
                          'cursor-pointer hover:bg-[#60a5fa] dark:hover:bg-[#60a5fa]': isToday(day) || isGameDay(day)
                        }"
              (click)="onDateClick(day)">
              <!-- overlay border for todays date -->
              <div *ngIf="isToday(day)"
                class="absolute inset-[-2px] z-[-1] border-4 border-[#3071ff] dark:border-white rounded-md pointer-events-none">
              </div>

              <span *ngIf="day" class="absolute top-0 right-1 text-xs">{{ day.getDate() }}</span>
              <span *ngIf="day && !isBeforeInitialDate(day) && !isAfterToday(day)" class="text-base md:text-lg font-bold">
                {{ getGameNumber(day) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end calendar -->
  </div>
</div>
