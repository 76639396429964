import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { ArchiveGame } from 'src/app/interfaces/ArchiveGame';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { ArchiveService } from 'src/app/services/archive.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit {
  user$: Observable<UserDetails>;
  archiveGames: ArchiveGame[] = [];

  currentDate: Date = new Date();
  currentMonth: number = this.currentDate.getMonth(); // Current month (0-11)
  currentYear: number = this.currentDate.getFullYear();
  calendarDays: (Date | null)[][] = [];

  constructor(
    private user: UserService,
    private router: Router,
    private archive: ArchiveService,
    private utilities: UtilitiesService,
    private data: DataService
  ) {
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit(): void {
    this.archiveGames = this.archive.getArchiveGames();
    this.generateCalendar();
  }

  goToArchiveGame(date: string) {
    this.router.navigate(['/'], {
      queryParams: { date: date },
    });
  }

  playRandomGame() {
    const random = Math.floor(Math.random() * this.archiveGames.length);
    const randomGame = this.archiveGames[random];
    this.router.navigate(['/'], {
      queryParams: {
        date: randomGame.date,
      },
    });
  }

  onDateClick(date: Date | null): void {
    if (!date || this.isBeforeInitialDate(date)) return;

    if (this.isToday(date)) {
      // nav to main game
      this.data.resetArchiveGame();
      this.router.navigate(['/']);
      return;
    }

    this.goToArchiveGame(dayjs(date).format('YYYY-MM-DD'));
    return;
  }

  // Generate the days of the current month
  generateCalendar() {
    this.calendarDays = [];
    const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
    const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);

    // Get the day of the week for the 1st day of the month
    const firstDay = firstDayOfMonth.getDay();

    // Get the total number of days in the month
    const totalDaysInMonth = lastDayOfMonth.getDate();

    // Start populating the calendar days
    let week: (Date | null)[] = [];
    for (let day = 1; day <= totalDaysInMonth; day++) {
      const currentDay = new Date(this.currentYear, this.currentMonth, day);

      // If it's the first day of the week, create a new week
      if (week.length === 7) {
        this.calendarDays.push(week);
        week = [];
      }

      // Fill the empty spaces before the first day of the month
      if (week.length === 0 && day === 1) {
        for (let i = 0; i < firstDay; i++) {
          week.push(null); // Nulls to align the first day of the month
        }
      }

      week.push(currentDay);
    }

    // Add empty cells at the end of the last row (to make a full week)
    while (week.length < 7) {
      week.push(null);
    }

    // Add the last week to the calendar
    if (week.length > 0) {
      this.calendarDays.push(week);
    }
  }

  goToPreviousMonth() {
    this.currentMonth--;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear--;
    }
    this.updateCurrentDate();
    this.generateCalendar();
  }

  goToNextMonth() {
    this.currentMonth++;
    if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear++;
    }
    this.updateCurrentDate();
    this.generateCalendar();
  }

  // Update the currentDate based on currentMonth and currentYear
  updateCurrentDate() {
    this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
  }

  isToday(date: Date | null): boolean {
    if (!date) return false;
    return dayjs(date).isSame(dayjs(), 'day');
  }

  isAfterToday(date: Date | null): boolean {
    if (!date) return false;
    return dayjs(date).isAfter(dayjs(), 'day');
  }

  isBeforeInitialDate(date: Date | null): boolean {
    if (!date) return false;

    return dayjs(date).isBefore(
      dayjs(this.utilities.getFirstWhereTakenGameDate()),
      'day'
    );
  }

  getGameNumber(date: Date | null): number | null {
    if (!date || this.isBeforeInitialDate(date)) return null;

    return this.utilities.getGameNumber(dayjs(date).format('YYYY-MM-DD'));
  }

  // Check if a given date is a game day
  isGameDay(date: Date | null): boolean {
    const gameNumber = this.getGameNumber(date);
    if (gameNumber === null) return false;
    return this.archiveGames.map((game) => game.number).includes(gameNumber);
  }
}
