import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  CityData,
  Country,
  CountryData,
  DayPuzzle,
  ITeuteufGameInfo,
  Landmark,
  LandmarkData,
} from '../interfaces/CDNInterfaces';
import { PlatformService } from './platform.service';
import { City } from '../interfaces/CDNInterfaces';

@Injectable({
  providedIn: 'root',
})
export class CDNDataService {
  private CDN_BASE_URL = 'https://teuteuf-dashboard-assets.pages.dev/';
  private CDN_DATA_URL = `${this.CDN_BASE_URL}data/`;
  private cache: { [endpoint: string]: any } = {};

  constructor(private http: HttpClient, private platform: PlatformService) {}

  private fetchJSONFromCDN(endpoint: string, useCache = true): Observable<any> {
    if (!this.platform.isInBrowser()) {
      return of(null);
    }

    if (useCache && this.cache[endpoint]) {
      return of(this.cache[endpoint]);
    }

    return this.http.get(this.CDN_DATA_URL + endpoint).pipe(
      tap((data) => {
        if (useCache) {
          this.cache[endpoint] = data;
        }
      })
    );
  }

  getGameInfo(): Observable<ITeuteufGameInfo> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/teuteuf-games/games-info.json
    return this.fetchJSONFromCDN('teuteuf-games/games-info.json');
  }

  getGameIconUrl(gameName: string): string {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/teuteuf-games/icons/worldle.svg
    return (
      this.CDN_DATA_URL +
      'teuteuf-games/icons/' +
      gameName.split(' ').join('_').toLowerCase() +
      '.svg'
    );
  }

  getCountryShapeSvgUrl(countryCode: string, cacheBuster?: string): string {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/country-shapes/gb.svg
    const cacheParam = cacheBuster ? `?ver=${cacheBuster}` : '';
    return `${
      this.CDN_DATA_URL
    }common/country-shapes/${countryCode.toLowerCase()}.svg${cacheParam}`;
  }

  getCountryData(countryCode: string): Observable<CountryData> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/countries/gb.json
    return this.fetchJSONFromCDN(
      `common/countries/${countryCode.toLowerCase()}.json`
    );
  }

  getCountries(langCode?: string): Observable<Array<Country>> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/countries.json
    return this.fetchJSONFromCDN('common/countries.json').pipe(
      map((data: Array<Country>) => {
        if (langCode) {
          // Localise name field
          data.forEach((c) => {
            if (c.names) {
              c.name = c.names[langCode] || c.names['en'] || c.name;
            }
          });
        }
        return data;
      })
    );
  }

  getCountryImageUrl(
    countryCode: string,
    photoCode: number,
    extension: 'jpg' | 'webp' = 'jpg',
    cacheBuster?: string
  ): string {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/country-images/ad/1.jpg
    const cacheParam = cacheBuster ? `?ver=${cacheBuster}` : '';
    return `${
      this.CDN_DATA_URL
    }common/country-images/${countryCode.toLowerCase()}/${photoCode}.${extension}${cacheParam}`;
  }

  getCities(langCode?: string): Observable<Array<City>> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/cities.json
    return this.fetchJSONFromCDN('common/cities.json').pipe(
      map((data: Array<City>) => {
        if (langCode) {
          // Localise name field
          data.forEach((c) => {
            if (c.names) {
              c.name = c.names[langCode] || c.names['en'] || c.name;
            }
          });
        }
        return data;
      })
    );
  }

  getCityImageUrl(
    cityData: CityData,
    extension: 'jpg' | 'webp' = 'jpg',
    cacheBuster?: string
  ): string {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/cities/1/1.jpg
    const cacheParam = cacheBuster ? `?ver=${cacheBuster}` : '';
    return `${this.CDN_DATA_URL}common/cities/${cityData.cityCode}/${cityData.imageCode}.${extension}${cacheParam}`;
  }

  getDayPuzzle(dayString: string): Observable<DayPuzzle> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/wheretaken-classic/games/2024/2024-03-01.json
    const [year] = dayString.split(/-/);
    return this.fetchJSONFromCDN(
      `wheretaken-classic/games/${year}/${dayString}.json`
    );
  }

  getLandmarkImageUrl(
    landmarkData: LandmarkData,
    extension: 'jpg' | 'webp' = 'jpg',
    cacheBuster?: string
  ): string {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/landmarks/1/1.jpg
    const cacheParam = cacheBuster ? `?ver=${cacheBuster}` : '';
    return `${this.CDN_DATA_URL}common/landmarks/${landmarkData.landmarkCode}/${landmarkData.imageCode}.${extension}${cacheParam}`;
  }

  getLandmarks(langCode?: string): Observable<Array<Landmark>> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/landmarks.json
    return this.fetchJSONFromCDN('common/landmarks.json').pipe(
      map((data: Array<Landmark>) => {
        if (langCode) {
          // Localise name field
          data.forEach((c) => {
            if (c.names) {
              c.name = c.names[langCode] || c.names['en'] || c.name;
            }
          });
        }
        return data;
      })
    );
  }

  getVersion(): Observable<number> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/version.json
    return this.fetchJSONFromCDN('version.json', false);
  }
}
