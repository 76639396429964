import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  take,
  firstValueFrom,
  interval,
} from 'rxjs';
import {
  ArchiveGame,
  CityGuess,
  dailyStats,
  Game,
  GameProgressStat,
  LandmarkGuess,
  Settings,
  Stats,
  StatsToUpdate,
} from './../interfaces/interfaces';
import {
  archiveGame,
  globalStats,
  initialData,
  settings,
} from './../data/data';
import { CountriesService } from './countries.service';
import { ImageService } from './image.service';
import { DialogsService } from './dialogs.service';
import { DatabaseService } from './database.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdsService } from './ads.service';
import { PlatformService } from './platform.service';
import { UserService } from './user.service';
import * as dayjs from 'dayjs';
import { AnalyticsService } from './analytics.service';
import { CDNDataService } from './cdn.service';
import {
  City,
  DayPuzzle,
  Landmark,
  Image,
  Country,
  CountryData,
} from '../interfaces/CDNInterfaces';
import { INCORRECT_FILL_COLOR } from '../components/svg-map/svg-map.component';
import { UtilitiesService } from './utilities.service';

// list of city states for which we skip capital round
const CITY_STATES = ['VA', 'MC', 'MO', 'SG', 'HK', 'GI'];

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private state = new BehaviorSubject(JSON.parse(JSON.stringify(initialData)));
  private settings = new BehaviorSubject(JSON.parse(JSON.stringify(settings)));
  private globalStats = new BehaviorSubject(
    JSON.parse(JSON.stringify(globalStats))
  );
  private archiveGame = new BehaviorSubject(
    JSON.parse(JSON.stringify(archiveGame))
  );
  private cities = new BehaviorSubject<City[]>([]);
  private landmarks = new BehaviorSubject<Landmark[]>([]);
  private countries = new BehaviorSubject<Country[]>([]);
  private countryData = new BehaviorSubject<CountryData | null>(null);

  private CDNversionKey = 'cdn-version';
  private currentCDNVersion = new BehaviorSubject<number>(0);
  private updateCDNVersion = new BehaviorSubject<number>(0);

  constructor(
    private countriesService: CountriesService,
    private imgService: ImageService,
    private image: ImageService,
    private dialog: DialogsService,
    private db: DatabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private ads: AdsService,
    private platform: PlatformService,
    private user: UserService,
    private analytics: AnalyticsService,
    private cdn: CDNDataService,
    private utilities: UtilitiesService
  ) {
    this.initCDNVersionCheck();
  }

  getState(): Observable<Game> {
    return this.state;
  }

  getStateObj(): Game {
    return this.state.value;
  }

  getSettings(): Observable<Settings> {
    return this.settings;
  }

  getGlobalStats(): Observable<dailyStats> {
    return this.globalStats;
  }

  getIsArchive(): Observable<ArchiveGame> {
    return this.archiveGame;
  }

  getCities(): Observable<City[]> {
    return this.cities.asObservable();
  }

  getLandmarks(): Observable<Landmark[]> {
    return this.landmarks.asObservable();
  }

  getCountries(): Observable<Country[]> {
    return this.countries.asObservable();
  }

  async fetchAndStoreCities(langCode: string = 'en'): Promise<void> {
    const citiesList = await firstValueFrom(this.cdn.getCities(langCode));
    this.cities.next(
      citiesList.filter((c) => c.name !== null && c.name !== undefined)
    );
  }

  async fetchAndStoreLandmarks(langCode: string = 'en'): Promise<void> {
    const landmarksList = await firstValueFrom(this.cdn.getLandmarks(langCode));
    this.landmarks.next(
      landmarksList.filter((c) => c.name !== null && c.name !== undefined)
    );
  }

  async fetchAndStoreCountries(langCode: string = 'en'): Promise<void> {
    const countriesList = await firstValueFrom(this.cdn.getCountries(langCode));
    this.countries.next(countriesList);
  }

  async fetchAndStoreCountry(countryCode: string): Promise<void> {
    const countryData = await firstValueFrom(
      this.cdn.getCountryData(countryCode)
    );
    this.countryData.next(countryData);
  }

  async fetchDayPuzzle(dayString: string): Promise<DayPuzzle> {
    const dayPuzzle = await firstValueFrom(this.cdn.getDayPuzzle(dayString));
    return dayPuzzle;
  }

  resetArchiveGame() {
    let aGame: ArchiveGame = this.archiveGame.value;
    aGame.isArchiveGame = false;
    this.archiveGame.next(aGame);
    this.createGame();
  }

  setArchiveGame(date: string) {
    let aGame: ArchiveGame = this.archiveGame.value;
    aGame.isArchiveGame = true;
    aGame.date = date;
    this.archiveGame.next(aGame);
  }

  loadStats() {
    //Get stats and put into behavior subject
    if (!this.platform.isInBrowser()) return;
    if (localStorage.getItem('stats') !== null) {
      let localStats = localStorage.getItem('stats');
      if (localStats !== null) {
        let statistics: Stats = JSON.parse(localStats);
        if (
          !this.isYesterday(statistics.lastWinDate) &&
          !this.isToday(statistics.lastWinDate)
        ) {
          statistics.currentStreak = 0;
        }
        this.user.getStatsBS().next(statistics);
      }
    }
  }

  loadDist() {
    if (!this.platform.isInBrowser()) return;
    let state = this.state.value;

    // Choosing miles or kilometers
    if (localStorage.getItem('distUnit') !== null) {
      state.distUnit = localStorage.getItem('distUnit');
    } else {
      state.distUnit = 'km';
    }
    this.state.next(state);
  }

  loadDifficulty() {
    if (!this.platform.isInBrowser()) return;

    let settings = this.settings.value;
    if (localStorage.getItem('difficulty') !== null) {
      settings.difficulty = localStorage.getItem('difficulty');
    } else {
      settings.difficulty = 'normal';
    }
    this.settings.next(settings);
  }

  async createGame() {
    if (!this.platform.isInBrowser()) return;

    // fetch data from CDN
    await Promise.all([
      this.fetchAndStoreCities(),
      this.fetchAndStoreLandmarks(),
      this.fetchAndStoreCountries(),
    ]);

    let state: Game = this.state.value;
    let archive: ArchiveGame = this.archiveGame.value;

    //Does player have Daily game in Local Storage?
    if (!archive.isArchiveGame) {
      //Does player have Daily game in Local Storage?
      let localStorageGame = localStorage.getItem('game');
      if (localStorageGame !== null) {
        let localGame: Game = JSON.parse(localStorageGame);

        // Load in preferred units of distance
        this.loadDist();
        const gameNumber = this.utilities.getGameNumber();
        if (localGame.gameNumber === gameNumber && localGame.newGame) {
          this.state.next(localGame);
          return;
        }
      }
    } else {
      //Does player have archive game in Local Storage?
      let localStorageGame = localStorage.getItem('archive-game');
      if (localStorageGame !== null) {
        let localGame: Game = JSON.parse(localStorageGame);
        this.loadDist();
        let gamePopulated = false;
        this.route.queryParams.pipe(take(1)).subscribe((val) => {
          if (val['date'] === localGame.date) {
            this.state.next(localGame);
            gamePopulated = true;
          }
        });
        if (gamePopulated) {
          return;
        }
      }
    }

    // Create a new game
    this.imgService.setLoading();
    this.state.next(JSON.parse(JSON.stringify(initialData)));
    state = this.state.value;

    // Check if supports webp
    state.data.supportsWebP = this.image.supportsWebP();
    if (state.data.supportsWebP) {
      state.data.imgExtension = 'webp';
    } else state.data.imgExtension = 'jpg';

    // Load in difficulty to current game
    let diff: string = this.settings.value.difficulty;
    state.difficulty = diff;

    this.loadDist();
    state = this.state.value;

    let date = dayjs().format('YYYY-MM-DD');
    let dayPuzzle = await this.fetchDayPuzzle(date);

    if (archive.isArchiveGame) {
      date = archive.date;
      dayPuzzle = await this.fetchDayPuzzle(date);
    }

    let country: Country | undefined = this.countries.value.find(
      (c) => c.code.toUpperCase() === dayPuzzle.countryCode.toUpperCase()
    );
    let photoCode = dayPuzzle.photoCode;

    if (country !== null && country !== undefined) {
      // MAIN ROUND
      await this.fetchAndStoreCountry(country.code);
      state.imgSrc = this.cdn.getCountryImageUrl(
        country.code,
        photoCode,
        state.data.imgExtension
      );
      state.imgLink =
        this.countryData.value?.images?.find((i) => i.imageCode === photoCode)
          ?.sourceLink ?? '';
      state.wikiLink =
        this.countryData.value?.links
          .find((l) => l.type === 'Wikipedia')
          ?.url.replace('${cc}', 'en') ??
        `https://en.wikipedia.org/wiki/${country.name}`;

      if (state.country === country.name) {
        this.imgService.setLoaded();
      }

      state.country = country.name;
      state.countryCode = country.code.toLowerCase();
      state.lat = country.latitude;
      state.long = country.longitude;
      // END MAIN ROUND

      // CAPITAL ROUND
      const countryCities = this.cities.value.filter(
        (c) => c.countryCode.toLowerCase() === country?.code.toLowerCase()
      );
      const countryCapitals = countryCities.filter((c) => c.capital);
      if (
        !countryCapitals.length ||
        CITY_STATES.includes(country.code.toUpperCase())
      ) {
        state.hasCapitalRound = false;
      } else {
        state.capitals = countryCapitals.map((c) => c.name);
        state.capitalCity.answers = countryCapitals.map((c) => c.name);
        state.map.guessesRemaining = this.getPinTheCapitalMaxTryCount(
          countryCapitals.length
        );

        // check svg
        const hasValidMap = await this.checkPinTheCapitalMap(
          country.code.toLowerCase(),
          countryCapitals.length
        );
        if (!hasValidMap) {
          state.hasCapitalRound = false;
        }
      }
      // END CAPITAL ROUND

      // LANDMARK ROUND
      const todaysLandmarkData = dayPuzzle.landmarkData;
      const todaysLandmark: Landmark | undefined = this.landmarks.value.find(
        (l) => l.landmarkCode === todaysLandmarkData.landmarkCode
      );
      const todaysLandmarkImage: Image | undefined =
        todaysLandmark?.images.find(
          (i) => i.imageCode === todaysLandmarkData?.imageCode
        );
      if (todaysLandmarkData && todaysLandmark && todaysLandmarkImage) {
        state.landmarks.imgSrc = todaysLandmarkImage.sourceLink;
        state.landmarks.wikiLink = todaysLandmark.infoLink;
        const landmarkAnswerSrc = this.cdn.getLandmarkImageUrl(
          todaysLandmarkData,
          state.data.imgExtension
        );
        state.landmarks.answerSrc = landmarkAnswerSrc;
        state.landmarks.answer = todaysLandmark.name;
        state.landmarks.locations.push({
          id: 1,
          name: todaysLandmark.name,
          country: country.name,
          isCorrect: true,
          isGuessed: false,
          imgSrc: landmarkAnswerSrc,
          shake: false,
          zoom: false,
        });

        const shuffledLandmarksList = this.utilities.shuffleWithSeed(
          this.landmarks.value.filter(
            (l) =>
              l.countryCode.toUpperCase() !== country?.code.toUpperCase() &&
              l.landmarkCode !== todaysLandmarkData.landmarkCode
          ),
          date
        );

        // add wrong answers for image quiz
        let i = 0;
        while (state.landmarks.locations.length < 4) {
          const landmark = shuffledLandmarksList[i];
          if (!landmark.images.length) {
            i++;
            continue;
          }
          // select an image
          const shuffledImageCodes = this.utilities.shuffleWithSeed(
            landmark.images.map((i) => i.imageCode),
            `${date}-landmark-images`
          );
          const imageCode = shuffledImageCodes[0];

          // Fetch landmark image; skip if it doesn't exist
          const landmarkUrl = this.cdn.getLandmarkImageUrl(
            { landmarkCode: landmark.landmarkCode, imageCode },
            state.data.imgExtension
          );
          const landmarkExists = await this.utilities.testImageLoad(
            landmarkUrl
          );

          if (!landmarkExists) {
            i++;
            continue;
          }

          state.landmarks.locations.push({
            id: 1,
            isCorrect: false,
            isGuessed: false,
            imgSrc: landmarkUrl,
            shake: false,
            zoom: false,
          });
          i++;
        }

        state.landmarks.locations = this.shuffle(state.landmarks.locations);
        let id = 1;
        state.landmarks.locations.forEach((el) => {
          el.id = id;
          id++;
        });

        // add correct answer for name quiz
        state.landmarksGuess.landmarks.push({
          value: state.landmarks.answer,
          isGuessed: false,
          isCorrect: true,
          shake: false,
        });
        // add wrong answers for name quiz
        shuffledLandmarksList.slice(0, 7).forEach((l) => {
          state.landmarksGuess.landmarks.push({
            value: l.name,
            isGuessed: false,
            isCorrect: false,
            shake: false,
          });
        });
        state.landmarksGuess.landmarks = this.shuffle(
          state.landmarksGuess.landmarks
        );
      }
      // END LANDMARK ROUND

      // CITY ROUND
      const todaysCityData = dayPuzzle.cityData;
      const todaysCity: City | undefined = this.cities.value.find(
        (c) => c.index === todaysCityData?.cityCode
      );
      const todaysCityImage: Image | undefined = todaysCity?.images?.find(
        (i) => i.imageCode === todaysCityData?.imageCode
      );
      if (
        !todaysCityData ||
        !todaysCity ||
        !todaysCityImage ||
        countryCities.length < 6
      ) {
        state.cityGuess.noImage = true;
        state.showLinks = true;
        state.cityGuess.shownConfetti = true;
        state.hasCityRound = false;
      } else {
        state.hasCityRound = true;
        state.cityGuess.imgSrc = this.cdn.getCityImageUrl(
          todaysCityData,
          state.data.imgExtension
        );
        state.cityGuess.imageLink = todaysCityImage.sourceLink;
        state.cityGuess.wikiLink = todaysCity.sourceLink ?? state.wikiLink;

        // Adding cities to game
        state.cityGuess.cities.push({
          value: todaysCity.name,
          isGuessed: false,
          isCorrect: true,
          shake: false,
        });
        countryCities.forEach((city) => {
          // only allow for 6 options
          if (
            state.cityGuess.cities.length < 6 &&
            city.name !== todaysCity.name
          ) {
            state.cityGuess.cities.push({
              value: city.name,
              isGuessed: false,
              isCorrect: false,
              shake: false,
            });
          }
        });

        state.cityGuess.cities = this.shuffle(state.cityGuess.cities);
      }
      // END CITY ROUND
    }

    state.gameNumber = this.utilities.getGameNumber(date);
    state.date = date;
    state.guesses = JSON.parse(JSON.stringify(initialData.guesses));
    state.guessNumber = 0;
    state.showShare = false;
    state.showAnswer = false;
    this.state.next(state);
    this.saveGame();
  }

  updatePreviousPage(route: string) {
    let state: Game = this.state.value;
    state.previousPage = route;
    this.state.next(state);
    this.saveGame();
  }

  navigateToPreviousPage() {
    const state: Game = this.state.value;
    this.router.navigate([state.previousPage]);
  }

  shuffle(array: any) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  getShareMsg(): string {
    const state: Game = this.state.value;
    const gameNumber = state.gameNumber;
    const numGuesses = state.guessNumber;
    const date = dayjs(state.date).format('DD.MM.YYYY');
    let msg = '';

    if (!state.showAnswer) {
      msg = `📷 #WhereTaken🌎 #${gameNumber} (${date}) ${numGuesses}/6`;
    } else {
      msg = `📷 #WhereTaken🌎 #${gameNumber} (${date}) X/6`;
    }
    if (state.difficulty === 'hard') {
      msg += '#HardMode';
    } else if (state.difficulty === 'veryhard') {
      msg += '#ExpertMode';
    }
    msg += '\n';

    for (let i = 0; i < state.guessNumber; i++) {
      state.guesses[i].squares.forEach((square) => {
        if (state.difficulty == 'normal') {
          if (square == 'green') {
            msg += '🟦';
          } else if (square == 'yellow') {
            msg += '🟨';
          } else {
            msg += '⬜';
          }
        } else {
          msg += '⬜';
        }
      });
      if (state.difficulty !== 'veryhard') {
        msg += state.guesses[i].direction + '\n';
      } else {
        if (i < state.guessNumber - 1 || state.showAnswer == true) {
          msg += '❌' + '\n';
        } else {
          msg += '✅' + '\n';
        }
      }
    }
    if (state.guessed) msg += '⭐';
    if (state.landmarks.guessed && state.landmarksGuess.guessed) msg += '⭐';
    if (state.map.guessed) msg += '⭐';
    if (state.cityGuess.guessed) msg += '⭐';

    msg += '\n\nwheretaken.teuteuf.fr';
    return msg;
  }

  updateInputValue(val: string) {
    let currentState = this.state.value;
    currentState.guessValue = val;
    this.state.next(currentState);
  }

  updateInputValueCity(val: string) {
    let currentState = this.state.value;
    currentState.capitalCity.guessValue = val;
    this.state.next(currentState);
  }

  updateStats() {
    if (!this.platform.isInBrowser()) return;
    let state: Game = this.state.value;
    let stats: Stats = this.user.getStatsObj();

    stats.played++;
    stats.gameNumber = state.gameNumber;

    // Just won the game
    if (state.showShare && !state.showAnswer) {
      stats.win++;
      //Check if on a streak, if so update it
      if (
        this.isYesterday(stats.lastWinDate) ||
        this.isToday(stats.lastWinDate)
      ) {
        stats.currentStreak++;
      } else {
        stats.currentStreak = 1;
      }

      if (stats.maxStreak < stats.currentStreak) {
        stats.maxStreak = stats.currentStreak;
      }
      stats.lastWinDate = new Date().toISOString();

      stats.guessDist[state.guessNumber]++;
    }
    // Just lost the game
    else if (state.showShare && state.showAnswer) {
      stats.currentStreak = 0;
    }

    stats.winPercent = Math.round((stats.win / stats.played) * 1000) / 10;
    this.user.getStatsBS().next(stats);
    localStorage.setItem('stats', JSON.stringify(stats));
    this.user.saveStatsToDB();
  }

  updateStatsManually(stats: StatsToUpdate) {
    if (!this.platform.isInBrowser()) return;
    const current: Stats = this.user.getStatsObj();
    let total = 0;

    stats.stats.forEach((stat) => {
      total += stat;
    });

    current.currentStreak = stats.currentStreak;
    current.maxStreak = stats.maxStreak;
    current.played = stats.totalGames;
    current.lastWinDate = new Date().toISOString();
    current.guessDist = stats.stats;
    current.win = total;
    current.winPercent = Math.round((total / stats.totalGames) * 1000) / 10;

    console.log(current);

    this.user.getStatsBS().next(current);
    localStorage.setItem('stats', JSON.stringify(current));
  }

  //Check if is yesterday
  isYesterday(date: string): boolean {
    const cYesterday = new Date(date);

    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (
      cYesterday.getFullYear() === yesterday.getFullYear() &&
      cYesterday.getMonth() === yesterday.getMonth() &&
      cYesterday.getDate() === yesterday.getDate()
    ) {
      return true;
    }
    return false;
  }

  isToday(date: string): boolean {
    const cToday = new Date(date);

    let today = new Date();

    if (
      cToday.getFullYear() === today.getFullYear() &&
      cToday.getMonth() === today.getMonth() &&
      cToday.getDate() === today.getDate()
    ) {
      return true;
    }
    return false;
  }

  saveGame() {
    if (!this.platform.isInBrowser()) return;
    const archive: ArchiveGame = this.archiveGame.value;
    const state: Game = this.state.value;

    if (!archive.isArchiveGame) {
      localStorage.setItem('game', JSON.stringify(state));
    } else {
      localStorage.setItem('archive-game', JSON.stringify(state));
    }
  }

  guess(val: string): void {
    const archive: ArchiveGame = this.archiveGame.value;

    // Find country
    let country: Country | undefined = this.countries.value.find(
      (c) => c.name.toLowerCase() === val.toLowerCase()
    );
    if (country === undefined) {
      this.triggerAlert();
      return;
    }

    // Update state
    let state: Game = this.state.value;
    let currentGuess = state.guesses[state.guessNumber];
    state.guessValue = '';
    currentGuess.isBlank = false;
    currentGuess.distance = this.countriesService.calcDistance(
      country.latitude,
      country.longitude,
      state.lat,
      state.long
    );
    // allow guesses to be correct by name
    if (country.code.toLowerCase() === state.countryCode.toLowerCase()) {
      currentGuess.distance = 0;
    }

    // Convert to miles
    currentGuess.distanceMI = Math.round(currentGuess.distance * 0.621371);
    currentGuess.percent = this.countriesService.calcPercentage(
      currentGuess.distance
    );

    currentGuess.squares = this.countriesService.calcSquares(
      currentGuess.percent
    );
    let bearing = this.countriesService.calcBearing(
      state.lat,
      state.long,
      country.latitude,
      country.longitude
    );
    // allow guesses to be correct by name
    if (country.code.toLowerCase() === state.countryCode.toLowerCase()) {
      bearing = 0;
    }
    currentGuess.direction = this.countriesService.getArrow(
      bearing,
      currentGuess.distance
    );
    currentGuess.isLoading = true;
    this.state.next(state);

    setTimeout(() => {
      currentGuess.country = val;
      if (state.guessNumber > 4 || currentGuess.distance === 0) {
        state.showShare = true;
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }

        this.preloadLandmarkImages();
        if (state.guessNumber > 4 && currentGuess.distance !== 0) {
          state.showAnswer = true;
        }
        if (currentGuess.distance === 0) {
          this.updateProgressBar();
        }
        if (!archive.isArchiveGame) this.updateStats();
        if (this.canTrack()) {
          const gameStatus =
            currentGuess.distance === 0 ? 'completed' : 'failed';
          const data: GameProgressStat = {
            puzzle: state.gameNumber,
            message: 'wheretaken',
            guesses: state.guesses.map((c) => c.country!),
            language: navigator.language,
            status: gameStatus,
          };
          this.db.logGameProgress(data);
        }
      }
      if (currentGuess.distance === 0) {
        state.guessed = true;
        state.stars.number++;
      }
      currentGuess.isGuessed = true;
      currentGuess.isLoading = false;

      state.guessNumber++;
      if (state.guessNumber === 3 && !state.showShare) {
        this.ads.startAuction('sidebar_left', 'desktop');
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }
      }

      this.state.next(state);
      this.saveGame();
    }, 1800);
  }

  canTrack() {
    const d = new Date();
    const h = d.getUTCHours();
    const pt: number[] = [16, 22];
    return pt.indexOf(h) !== -1 || window.origin.indexOf('staging') !== -1;
  }

  preloadImagesRoundOne() {
    let args: string[] = [];
    args.push('/assets/star-filled.svg');
    args.push('/assets/share.svg');
    this.preload(...args);
  }

  updateProgressBar() {
    let state: Game = this.state.value;
    let percent = 25;

    this.state.next(state);
    this.saveGame();
    let offset = 0;

    for (let i = state.stars.percent; i <= state.stars.percent + percent; i++) {
      offset++;
      setTimeout(() => {
        state.stars.percent = i;
        if (state.stars.percent == 25) {
          state.stars.stars[0].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[0].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 50) {
          state.stars.stars[1].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[1].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 75) {
          state.stars.stars[2].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[2].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 100) {
          state.stars.stars[3].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[3].shownAnimation = true;
          }, 500);
        }
        this.state.next(state);
      }, offset * 20);
    }

    setTimeout(() => {
      this.saveGame();
    }, percent * 20 + 1000);
    this.state.next(state);
  }

  triggerAlert() {
    let state = this.state.value;
    state.showAlert = true;
    this.state.next(state);
    setTimeout(() => {
      state.showAlert = false;
      this.state.next(state);
    }, 2000);
    return;
  }

  updateDistUnit(units: string) {
    if (!this.platform.isInBrowser()) return;
    let state = this.state.value;
    state.distUnit = units;
    localStorage.setItem('distUnit', units);
    this.state.next(state);
    this.saveGame();
  }

  updateConfetti() {
    let state: Game = this.state.value;
    state.shownConfetti = true;
    this.state.next(state);
  }

  updateDifficulty(difficulty: string) {
    if (!this.platform.isInBrowser()) return;
    let settings: Settings = this.settings.value;
    settings.difficulty = difficulty;
    localStorage.setItem('difficulty', difficulty);
    this.settings.next(settings);
  }

  preload(...args: any[]): void {
    if (!this.platform.isInBrowser()) return;
    let imgs = new Array();
    for (var i = 0; i < args.length; i++) {
      imgs[i] = new Image();
      imgs[i].src = args[i];
    }
  }

  // Landmark page
  landmarkGuess(landmark: any) {
    let state: Game = this.state.value;
    if (state.landmarks.roundOver || landmark.isGuessed) {
      return;
    }
    state.landmarks.guessesRemaining--;
    for (let i = 0; i < state.landmarks.buttons.length; i++) {
      state.landmarks.buttons[i] = false;
    }

    if (landmark.isCorrect) {
      state.landmarks.guessed = true;
      state.landmarks.guesses++;
      state.landmarks.locations.forEach((landmark, i) => {
        landmark.isGuessed = true;
      });
      state.landmarks.roundOver = true;
      this.state.next(state);
      state.landmarks.shownConfetti = true;
      this.state.next(state);
      this.saveGame();
      setTimeout(() => {
        state.landmarksGuess.showRound = true;
        this.state.next(state);
        this.saveGame();
      }, 1000);
    } else {
      state.landmarks.locations[landmark.id - 1].isGuessed = true;
      state.landmarks.locations[landmark.id - 1].shake = true;
      state.landmarks.guesses++;
      setTimeout(() => {
        state.landmarks.locations[landmark.id - 1].shake = false;
        if (state.landmarks.guesses >= 2) {
          state.landmarks.roundOver = true;
          state.landmarks.locations.forEach((location) => {
            location.isGuessed = true;
          });
          setTimeout(() => {
            state.landmarksGuess.showRound = true;
            this.state.next(state);
            this.saveGame();
          }, 300);
        }
        this.saveGame();
      }, 300);
    }

    this.state.next(state);
    this.saveGame();
  }

  preloadLandmarkImages() {
    const state: Game = this.state.value;
    let args: string[] = [];

    state.landmarks.locations.forEach((location) => {
      args.push(location.imgSrc);
    });

    args.push('/assets/increase.png');
    args.push('/assets/decrease.png');
    args.push('/assets/star-filled.svg');
    args.push('/assets/close.svg');

    this.preload(...args);
  }

  updateLandmarkZoom(id: number) {
    let state: Game = this.state.value;
    state.landmarks.zoom = !state.landmarks.zoom;

    state.landmarks.locations.forEach((landmark) => {
      if (landmark.id === id) {
        landmark.zoom = !landmark.zoom;
        state.landmarks.zoomImage = landmark.imgSrc;
      }
    });
    this.state.next(state);
    this.saveGame();
  }

  // LANDMARK NAME GUESS
  landmarkNameGuess(landmark: LandmarkGuess, index: number) {
    let state: Game = this.state.value;
    if (state.landmarksGuess.roundOver) return;

    state.landmarksGuess.landmarks[index].isGuessed = true;

    if (landmark.isCorrect) {
      state.landmarksGuess.guessed = true;
      state.landmarksGuess.roundOver = true;
      this.state.next(state);
      state.landmarksGuess.shownConfetti = true;
      this.state.next(state);
      // Only give star if landmark image correct
      if (state.landmarks.guessed) {
        state.stars.number++;
        this.updateProgressBar();
      }
      if (state.topAdOpen) {
        this.ads.startAuction('top_banner', 'mobile');
      }
    } else {
      state.landmarksGuess.guessesRemaining--;
      if (state.landmarksGuess.guessesRemaining <= 0) {
        state.landmarksGuess.roundOver = true;
        state.landmarksGuess.landmarks.forEach((landmark) => {
          if (landmark.isCorrect) {
            landmark.isGuessed = true;
          }
        });
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }
      }
      state.landmarksGuess.landmarks[index].shake = true;
      setTimeout(() => {
        state.landmarksGuess.landmarks[index].shake = false;
      }, 4000);
    }

    this.state.next(state);
    this.saveGame();
  }

  showButtons(index: number) {
    let state: Game = this.state.value;

    if (
      state.landmarks.roundOver ||
      state.landmarks.locations[index].isGuessed
    ) {
      return;
    }

    for (let i = 0; i < state.landmarks.buttons.length; i++) {
      state.landmarks.buttons[i] = false;
    }
    state.landmarks.buttons[index] = true;
    this.state.next(state);
    this.saveGame();
  }

  hideButtons(index: number) {
    let state: Game = this.state.value;
    state.landmarks.buttons[index] = false;
    this.state.next(state);
    this.saveGame();
  }

  // MAP PAGE

  capitalCityGuess(guess: string, cityNames: string[]) {
    // Find city
    const found = cityNames.find(
      (city) => city.trim().toLowerCase() === guess?.trim().toLowerCase()
    );

    if (found === null || found === undefined) {
      this.triggerAlert();
      return;
    }

    // Update state
    let state: Game = this.state.value;
    let currentGuess = state.capitalCity.guesses[state.capitalCity.guessNumber];
    state.capitalCity.guessNumber++;
    currentGuess.city = guess.trim();
    currentGuess.isBlank = false;
    currentGuess.isLoading = true;

    this.state.next(state);
    setTimeout(() => {
      // Is correct
      if (
        state.capitalCity.answers
          .map((a) => a.trim().toLowerCase())
          .includes(guess.trim().toLowerCase())
      ) {
        currentGuess.isCorrect = true;
        state.capitalCity.showConfetti = true;
        state.capitalCity.roundOver = true;
        state.capitalCity.guessed = true;
      }
      currentGuess.isLoading = false;
      currentGuess.isGuessed = true;
      if (state.capitalCity.guessNumber >= 3) {
        state.capitalCity.roundOver = true;
        state.capitalCity.showAnswer = true;
      }

      if (state.capitalCity.roundOver) {
        setTimeout(() => {
          state.map.showRound = true;
          this.state.next(state);
          this.saveGame();
        }, 1200);
      }
      this.state.next(state);
      this.saveGame();
    }, 1800);
  }

  cityConfetti() {
    let state: Game = this.state.value;
    state.capitalCity.shownConfetti = true;
    this.state.next(state);
  }

  mapGuess(guess: string) {
    let state: Game = this.state.value;
    if (state.map.guessed || state.map.roundOver) {
      return;
    }
    state.map.guessesRemaining--;

    if (guess.includes('right')) {
      state.map.correctGuesses.push(guess);

      // check if got all correct
      if (
        state.map.correctGuesses.length === state.capitalCity.answers.length
      ) {
        state.map.guessed = true;
        state.map.roundOver = true;
        if (state.capitalCity.guessed) {
          state.stars.number++;
          this.updateProgressBar();
        }
      }

      // check if ran out of guesses
      if (state.map.guessesRemaining <= 0) {
        state.map.roundOver = true;
      }

      this.state.next(state);
      this.saveGame();
    } else {
      state.map.incorrectGuesses.push(guess);
      state.map.shake = true;
      if (state.map.guessesRemaining <= 0) {
        state.map.roundOver = true;
      }
      this.state.next(state);
      setTimeout(() => {
        state.map.shake = false;
        this.state.next(state);
        this.saveGame();
      }, 300);
    }
    if (state.map.roundOver) {
      this.preLoadCityImage();
      if (state.topAdOpen) {
        this.ads.startAuction('top_banner', 'mobile');
      }
    }
  }

  async checkPinTheCapitalMap(
    countryCode: string,
    numCapitals: number
  ): Promise<boolean> {
    try {
      const response = await fetch(this.cdn.getCountryShapeSvgUrl(countryCode));
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');

      const circles = svgDoc.querySelectorAll('circle');
      let correctCount = 0;
      let incorrectCount = 0;
      circles.forEach((circle) => {
        const fillColor = circle.getAttribute('fill');
        if (fillColor === INCORRECT_FILL_COLOR) {
          incorrectCount++;
        } else {
          correctCount++;
        }
      });

      if (
        circles.length >= 4 &&
        correctCount === numCapitals &&
        incorrectCount > 2 &&
        incorrectCount + correctCount >=
          this.getPinTheCapitalMaxTryCount(numCapitals)
      ) {
        return true;
      }

      return false;
    } catch {
      return false;
    }
  }

  getPinTheCapitalMaxTryCount = (numCapitals: number): number => {
    return 2 + (numCapitals - 1);
  };

  //CITY PAGE
  cityGuess(city: CityGuess, index: number) {
    let state: Game = this.state.value;
    if (state.cityGuess.roundOver) return;

    state.cityGuess.cities[index].isGuessed = true;

    if (city.isCorrect) {
      state.cityGuess.guessed = true;
      state.cityGuess.roundOver = true;
      this.state.next(state);
      state.cityGuess.shownConfetti = true;
      this.state.next(state);
      state.stars.number++;
      this.updateProgressBar();
      this.preloadTurtle();
      this.populateTurtleSrc();
      setTimeout(() => {
        state.showLinks = true;
        this.state.next(state);
        this.saveGame();
      }, 1000);
    } else {
      state.cityGuess.guessesRemaining--;
      if (state.cityGuess.guessesRemaining <= 0) {
        state.cityGuess.roundOver = true;
        state.cityGuess.cities.forEach((landmark) => {
          if (landmark.isCorrect) {
            landmark.isGuessed = true;
          }
        });
        this.preloadTurtle();
        this.populateTurtleSrc();
        setTimeout(() => {
          state.showLinks = true;
          this.state.next(state);
          this.saveGame();
        }, 600);
      }
      state.cityGuess.cities[index].shake = true;
      setTimeout(() => {
        state.cityGuess.cities[index].shake = false;
      }, 4000);
    }

    this.state.next(state);
    this.saveGame();
  }

  preLoadCityImage() {
    const state: Game = this.state.value;
    if (state.cityGuess?.noImage) {
      return;
    }

    let args: string[] = [];

    args.push(state.cityGuess.imgSrc);

    this.preload(...args);
  }

  populateTurtleSrc() {
    let state: Game = this.state.value;
    state.turtleSrc = `./assets/turtles/turtle${state.stars.number}.${state.data.imgExtension}`;
    this.state.next(state);
    this.saveGame();
  }

  checkIfNoImage() {
    let state: Game = this.state.value;
    if (state.cityGuess?.noImage && !state.cityGuess.roundOver) {
      state.cityGuess.roundOver = true;
      state.cityGuess.guessed = true;
      state.stars.number++;
      this.state.next(state);
      this.populateTurtleSrc();
      setTimeout(() => {
        this.updateProgressBar();
      }, 300);
    }
    this.state.next(state);
    this.saveGame();
  }

  checkIfNoMap() {
    let state: Game = this.state.value;
    if (!state.hasCapitalRound && !state.map.roundOver) {
      state.map.roundOver = true;
      state.map.guessed = true;
      state.capitalCity.guessed = true;
      state.stars.number++;
      this.state.next(state);
      setTimeout(() => {
        this.updateProgressBar();
      }, 300);
    }
    this.state.next(state);
    this.saveGame();
  }

  checkIfNoCity() {
    let state: Game = this.state.value;
    if (!state.hasCityRound && !state.cityGuess.roundOver) {
      state.cityGuess.roundOver = true;
      state.cityGuess.guessed = true;
      state.stars.number++;
      state.showLinks = true;
      this.state.next(state);
      setTimeout(() => {
        this.updateProgressBar();
      }, 300);
    }
    this.populateTurtleSrc();
    this.state.next(state);
    this.saveGame();
  }

  toggleTopAdOpen() {
    let state: Game = this.state.value;
    state.topAdOpen = !state.topAdOpen;
    this.state.next(state);
    this.saveGame();
  }

  titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  preloadTurtle() {
    const state: Game = this.state.value;

    let args: string[] = [];
    args.push(
      `./assets/turtles/turtle${state.stars.number}.${state.data.imgExtension}`
    );
    this.preload(...args);
  }

  checkShouldAdvertiseWhereTakenNew() {
    if (!this.platform.isInBrowser()) return;

    if (localStorage.getItem('wheretaken-new') == null) {
      this.dialog.openWhereTakenNew();

      localStorage.setItem('wheretaken-new', '' + Date.now());
    }
  }

  inIFrame(): boolean {
    if (!this.platform.isInBrowser()) return false;

    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  checkForIFrame() {
    if (this.inIFrame()) {
      this.dialog.openIFrameWarning();
      this.analytics.sendEvent('iframe_popup', {});
    }
  }

  // CDN version checking
  async initCDNVersionCheck() {
    let savedVersion = Number(localStorage.getItem(this.CDNversionKey));
    if (!savedVersion) {
      savedVersion = await firstValueFrom(this.cdn.getVersion());
      localStorage.setItem(this.CDNversionKey, String(savedVersion));
    }
    this.currentCDNVersion.next(savedVersion);

    this.checkCDNVersion();
    interval(20000).subscribe(() => this.checkCDNVersion());
  }

  async checkCDNVersion() {
    const latestVersion = await firstValueFrom(this.cdn.getVersion());
    const currentVersion = this.currentCDNVersion.value;

    if (latestVersion > currentVersion) {
      this.updateCDNVersion.next(latestVersion);
      this.dialog.openCDNUpdateToast();
    }
  }

  updateToLatestCDNVersion() {
    const latestVersion = this.updateCDNVersion.value;
    localStorage.setItem(this.CDNversionKey, String(latestVersion));
    window.location.reload();
  }
}
